import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FilteredList,
  Accordion,
  MultipleComponentIterator,
  SlideReveal,
  FishermanIcon,
  InternalLink,
  MultipleComponentIteratorMap,
  PriceRange,
  HoverContainer,
  TeamMemberV2,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuItem,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <Grid stackable={true}>
            <Grid.Column width={16} className={"header-column"}>
              <Header as={"h1"}>
                <span
                  className={"no-highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "services_header",
                  })}
                />
                <span
                  className={"highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "services_header_highlighted",
                  })}
                />
              </Header>
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_description",
                })}
              />
            </Grid.Column>
            <Grid.Column width={16} className={"services-list-column"}>
              <FilteredList
                items={allFishermanBusinessMenuCategory.nodes}
                itemFilterKey={"schedule.name"}
                childItemsKey={"iterator"}
                defaultFilterValue={"All Services"}
                displayAllFilterValue={"All Services"}
                filterValues={allFishermanBusinessMenuSchedule.nodes}
                filterValueKey={"name"}
                filterValuesComponent={<Button basic={true} />}
                parentContainer={
                  <Accordion
                    exclusive={true}
                    className={"services-accordion"}
                  />
                }
                className={"services-filtered-list"}
              >
                <MultipleComponentIterator
                  components={[
                    {
                      component: <React.Fragment />,
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: <Accordion.Title />,
                          propMap: { index: "__itemIndex__" },
                          children: [
                            {
                              component: (
                                <SlideReveal
                                  fade={true}
                                  direction={"right"}
                                  firstAnimation={{ direction: "up" }}
                                />
                              ),
                              children: [
                                {
                                  component: <Header as={"h2"} />,
                                  children: [
                                    {
                                      component: (
                                        <span children={{ name: "name" }} />
                                      ),
                                      propMap: { children: "name" },
                                    },
                                    {
                                      component: (
                                        <FishermanIcon iconName={"plus"} />
                                      ),
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          component: <Accordion.Content />,
                          propMap: { index: "__itemIndex__" },
                          children: [
                            {
                              component: <MarkdownContent />,
                              propMap: { content: "description" },
                            },
                            {
                              component: (
                                <Button
                                  primary={true}
                                  content={getComponentContentNodeContent({
                                    components:
                                      fishermanBusinessWebsitePage.components,
                                    componentId:
                                      "service_category_details_button",
                                    defaultValue: "View Details",
                                    enforceDefault: true,
                                  })}
                                  event={{
                                    category: "Services",
                                    action: "View Category Details",
                                  }}
                                  as={InternalLink}
                                />
                              ),
                              propMap: {
                                "event.label": "name",
                                to: createServiceCategorySlug,
                              },
                            },
                            {
                              component: (
                                <Grid
                                  className={"service-category-items"}
                                  doubling={true}
                                  stackable={true}
                                  columns={2}
                                />
                              ),
                              children: [
                                {
                                  component: (
                                    <MultipleComponentIteratorMap
                                      lookupField={"_id"}
                                      iterator={
                                        allFishermanBusinessMenuItem.nodes
                                      }
                                      components={[
                                        {
                                          component: <Grid.Column />,
                                          children: [
                                            {
                                              component: (
                                                <InternalLink
                                                  event={{
                                                    category: "Services",
                                                    action: "View Item Details",
                                                  }}
                                                />
                                              ),
                                              propMap: {
                                                "event.label": "name",
                                                to: withArgs({
                                                  func: createServiceItemSlug,
                                                  args: [
                                                    allFishermanBusinessMenuCategory.nodes,
                                                  ],
                                                }),
                                              },
                                              children: [
                                                {
                                                  component: (
                                                    <Header as={"h3"} />
                                                  ),
                                                  propMap: { children: "name" },
                                                },
                                                {
                                                  component: (
                                                    <PriceRange
                                                      priceKey={"price"}
                                                      settings={{
                                                        displayType: "range",
                                                        displayZeroCost: false,
                                                      }}
                                                    />
                                                  ),
                                                  propMap: {
                                                    prices: "variations",
                                                  },
                                                },
                                                {
                                                  component: (
                                                    <FishermanIcon
                                                      iconName={"arrow-right"}
                                                    />
                                                  ),
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ]}
                                    />
                                  ),
                                  propMap: { targetValues: "items" },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                />
              </FilteredList>
            </Grid.Column>
            <Grid.Column width={16} className={"featured-team-column"}>
              <div className={"team-content-section"}>
                <Header
                  as={"h2"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "team_section_header",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "team_section_description",
                  })}
                />
                <Button
                  to={"/team/"}
                  primary={true}
                  event={{ category: "Team", action: "View All Team Members" }}
                  as={InternalLink}
                >
                  See Our Team
                </Button>
              </div>
              <div className={"team-members-section"}>
                <Grid
                  className={"featured-stylists-grid"}
                  columns={2}
                  stackable={true}
                >
                  <MultipleComponentIterator
                    iterator={allFishermanBusinessTeam.nodes}
                    limit={8}
                    components={[
                      {
                        component: (
                          <Grid.Column
                            as={InternalLink}
                            className={"team-member"}
                            event={{
                              category: "Team",
                              action: "View Team Member",
                            }}
                          />
                        ),
                        propMap: {
                          key: "_id",
                          "event.label": "name",
                          to: createTeamMemberSlug,
                        },
                        children: [
                          {
                            component: <HoverContainer />,
                            children: [
                              {
                                component: (
                                  <SlideReveal
                                    duration={500}
                                    direction={"up"}
                                    triggerOnce={true}
                                    distance={"6px"}
                                  />
                                ),
                                children: [
                                  {
                                    component: (
                                      <div className={"name-container"} />
                                    ),
                                    children: [
                                      {
                                        component: <Header />,
                                        propMap: { content: "name" },
                                      },
                                      {
                                        component: (
                                          <HoverContainer.Content
                                            displayOnTouchScreen={true}
                                            animation={"height"}
                                            animationProps={{
                                              duration: 750,
                                              fade: true,
                                            }}
                                          />
                                        ),
                                        children: [
                                          {
                                            component: (
                                              <FishermanIcon
                                                iconName={"arrow-right"}
                                              />
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    component: (
                                      <HoverContainer.Content
                                        displayOnTouchScreen={true}
                                        animation={"height"}
                                        animationProps={{
                                          duration: 750,
                                          fade: true,
                                        }}
                                      />
                                    ),
                                    children: [
                                      {
                                        component: <span className={"role"} />,
                                        propMap: { children: "role" },
                                      },
                                    ],
                                  },
                                  {
                                    component: (
                                      <HoverContainer.Content
                                        displayOnTouchScreen={false}
                                        animation={"height"}
                                        animationProps={{
                                          duration: 750,
                                          fade: true,
                                        }}
                                      />
                                    ),
                                    children: [
                                      {
                                        component: <TeamMemberV2.Social />,
                                        propMap: { social: "socialMedia" },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                  />
                </Grid>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [order], order: [ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        socialMedia {
          platform
          url
        }
        show
      }
    }
  }
`;
